exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-links-old-index-js": () => import("./../../../src/pages/links_old/index.js" /* webpackChunkName: "component---src-pages-links-old-index-js" */),
  "component---src-pages-politicas-de-privacidade-js": () => import("./../../../src/pages/politicas-de-privacidade.js" /* webpackChunkName: "component---src-pages-politicas-de-privacidade-js" */),
  "component---src-templates-attendants-js": () => import("./../../../src/templates/attendants.js" /* webpackChunkName: "component---src-templates-attendants-js" */),
  "component---src-templates-authors-js": () => import("./../../../src/templates/authors.js" /* webpackChunkName: "component---src-templates-authors-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-links-js": () => import("./../../../src/templates/links.js" /* webpackChunkName: "component---src-templates-links-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

